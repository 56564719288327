import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  buildQuery,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';
import { Checkbox } from '@paljs/ui';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const CloseJobCard = () => {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [jobCardOptions, setJobCardOptions] = useState([]);
  const [pocOptions, setPocOptions] = useState([]);
  const [apiResponse, setApiResponse] = useState([]);
  const [jobCards, setJobCards] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPages] = useState(0);
  const [labels, setLabels] = useState({ customer: '', sort: [], jobCard: [], poc: [], shadeString: '' });
  const [currSpecHeadData, setCurrSpecHeadData] = useState({
    customer: { name: '', id: '' },
    poc: { id: '' },
    sort: { sortName: '', id: '' },
  });
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState('');
  const [totMeters, setTotMeters] = useState({ totalMeters: 0 });
  const [currShadeString, setCurrShadeString] = useState('');
  const [sgAll, setSgAll] = useState(false);
  const [dsAll, setDsAll] = useState(false);
  const filterColumns = ['id', 'customer.name', 'processOrder.id', 'customerSort.sortName', 'totalMeters'];
  const [currId, setCurId] = useState('');
  const optionsSetter = (response, customer = true, sort = true, jobCard = true, poc = true) => {
    const currJobCardOptions = [{ label: 'All', value: 'all' }];
    const currCustomerOptions = [];
    const currCustSortOptions = [];
    const currPocOptions = [];
    response.forEach((t) => {
      let temJbOpt = {
        label: `${t?.id}-${t?.attributes?.idLotNum}`,
        value: `${t?.id}`,
      };
      let temCustOpt = {
        label: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name}`,
        value: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.id}`,
      };
      let temSortOpt = {
        label: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.sortName}`,
        value: `${t?.attributes?.processOrder?.data?.attributes?.customersSort?.data?.id}`,
      };
      let temPocOptions = {
        label: `${t?.attributes?.processOrder?.data?.id}`,
        value: `${t?.attributes?.processOrder?.data?.id}`,
      };
      if (temJbOpt.label !== 'undefined') {
        currJobCardOptions.push(temJbOpt);
      }
      if (temSortOpt.label !== 'undefined') {
        currCustSortOptions.push(temSortOpt);
      }
      if (temCustOpt.label !== 'undefined') {
        currCustomerOptions.push(temCustOpt);
      }
      if (temPocOptions.label !== 'undefined') {
        currPocOptions.push(temPocOptions);
      }
    });
    if (jobCard) {
      setJobCardOptions(arrUnique(currJobCardOptions));
    }
    if (customer) {
      setCustomerOptions(arrUnique(currCustomerOptions));
    }
    if (poc) {
      setPocOptions(arrUnique(currPocOptions));
    }
    if (sort) {
      setSortOptions(arrUnique(currCustSortOptions));
    }
  };
  const getApi = () => {
    axios
      .get(
        `${baseUri()}packing-slips?sort[0]=jobCard.id:desc&fields[0]=dispatch&populate[jobCard][fields][0]=idLotNum&populate[jobCard][populate][processOrder][fields][0]=id&populate[jobCard][populate][processOrder][fields][1]=shade&populate[jobCard][populate][processOrder][fields][2]=pocType&populate[jobCard][populate][processOrder][populate][customersSort][populate][user][fields][0]=name&populate[jobCard][populate][processOrder][populate][customersSort][fields][0]=sortName&filters[jobCard][status][$eq]=open`,
      )
      .then((response) => {
        response = response.data.data;
        response = response.map((t) => t.attributes?.jobCard?.data);
        setApiResponse(response);
        optionsSetter(response);
        hideLoader();
      });
  };
  const getTableData = () => {
    axios
      .get(
        baseUri() +
          buildQuery(
            `spec-gens?sort[0]=id:desc&fileds[0]=id&populate[customer][fields][0]=name&populate[customerSort][fields][0]=sortName&populate[processOrder][fields][0]=id&pagination[page]=${page}`,
            tableFilter,
            filterColumns,
          ),
      )
      .then((response) => {
        setTableData(response.data.data);
        setTotalPages(response.data.meta.pagination.pageCount);
      });
  };
  const arrUnique = (arr) => {
    const map = new Map();
    arr.forEach((itm) => {
      map.set(JSON.stringify(itm), itm);
    });
    return [...map.values()];
  };
  const customerFilterOptions = (labelValue = labels.customer.label) => {
    let filteredApiResponse = apiResponse.filter(
      (t) =>
        t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name ===
        labelValue,
    );
    return filteredApiResponse;
  };
  const sortFilterOptions = (currLabel = labels.sort) => {
    let filteredApiResponse = apiResponse.filter(
      (t) =>
        t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.sortName === currLabel.label,
    );
    return filteredApiResponse;
  };
  const pocFilterOptions = (currLabel = labels.poc) => {
    let filteredApiResponse = apiResponse.filter((t: any) => {
      return +t?.attributes?.processOrder?.data?.id === +currLabel.label;
    });
    return filteredApiResponse;
  };
  const jobCardFilterChange = (label = labels) => {
    if (label.jobCard.length === 0) {
      optionsSetter(apiResponse, false);
    }
    let filteredApiResponse = [];
    let custMap = new Map();
    label.jobCard.forEach((currLabel) => {
      let currArr = apiResponse.filter((t: any) => {
        if (+t.id === +currLabel.value) {
          custMap.set(
            t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes?.name,
            {
              customer: {
                label:
                  t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.attributes
                    ?.name,
                value: t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.user?.data?.id,
              },
              sort: {
                label: t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.attributes?.sortName,
                value: t.attributes?.processOrder?.data?.attributes?.customersSort?.data?.id,
              },
              poc: {
                label: t.attributes?.processOrder?.data?.id,
                value: t.attributes?.processOrder?.data?.id,
              },
              shadeString: `${t.attributes?.processOrder?.data?.attributes?.pocType}-${t.attributes?.processOrder?.data?.attributes?.shade}`,
            },
          );
        }
        return +t.id === +currLabel.value;
      });
      filteredApiResponse.push(currArr);
    });
    filteredApiResponse = filteredApiResponse.flat();
    optionsSetter(filteredApiResponse, false, true, false, true);
    const customersVal = [...custMap.values()];
    if (customersVal.length === 1 && customersVal[0].customer.label !== undefined) {
      setLabels({ ...label, ...customersVal[0] });
    } else {
      setLabels({
        ...label,
        customer: '',
        sort: '',
        poc: '',
        shadeString: '',
      });
    }
  };
  const getJobCards = async () => {
    showLoader();
    let jobCardIds = labels.jobCard.map((t) => +t.value);
    let response = await axios.get(`${baseUri()}job-card/close?jobCards=${jobCardIds.join(',')}`);
    response = await response.data.data;
    setCurrSpecHeadData({
      customer: { name: labels.customer.label, id: labels.customer.value },
      poc: { id: labels.poc.label },
      sort: { sortName: labels.sort.label, id: labels.sort.value },
    });
    setJobCards(response);
    setCurId('');
    hideLoader();
  };

  const handleJobCardChange = (idx, key, value) => {
    let currJobCardArray = [...jobCards];
    currJobCardArray[idx][key] = value ? 'close' : 'open';
    setJobCards(currJobCardArray);
  };

  const authState = useContext(GlobalStateData);
  const userId = authState.authId;
  const userEmail = authState.authEmail;
  const allowedOpenJbCrdUsers = ['ubledp@gmail.com'];
  useEffect(() => {
    showLoader();
    getApi();
  }, []);
  useEffect(() => {
    getTableData();
  }, [page]);
  useEffect(() => {
    let currTot = { totalMeters: 0 };
    jobCards.forEach((t) => {
      if (t.sg) {
        if (currTot[t.category]) {
          currTot[t.category] += +t.baleTotal;
        } else {
          currTot[t.category] = 0;
          currTot[t.category] += +t.baleTotal;
        }
        currTot.totalMeters += +t.baleTotal;
      }
    });
    setTotMeters(currTot);
  }, [jobCards]);
  let totString = '';
  for (let i in totMeters) {
    if (i === 'totalMeters') {
      totString += `Total:${totMeters[i]}, `;
    } else {
      totString += `${i}:${totMeters[i]}, `;
    }
  }
  return (
    <>
      <SEO title="Job Card - Close" />
      <Col breakPoint={{ xs: 12, md: 12 }}>
        <Card>
          <header>Job Card - Close</header>
          <CardBody className="cardbody">
            <Row>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>Customer</label>
                <SelectField
                  fullWidth
                  options={customerOptions}
                  id="customer"
                  name="customer"
                  placeholder="Select Customer"
                  value={labels.customer}
                  onChange={(e) => {
                    setLabels({
                      ...labels,
                      poc: '',
                      sort: '',
                      customer: {
                        label: e.label,
                        value: e.value,
                      },
                      jobCard: [],
                      shadeString: '',
                    });
                    optionsSetter(customerFilterOptions(e.label), false);
                  }}
                  className="pull-down multi-select"
                />
              </Col>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>Customer Sort</label>
                <SelectField
                  fullWidth
                  options={sortOptions}
                  id="customerSort"
                  name="customerSort"
                  placeholder="Select Customer Sort"
                  value={labels.sort}
                  onChange={(e) => {
                    const currFilteredSortOptions = sortFilterOptions(e);
                    setLabels({
                      ...labels,
                      sort: e,
                      customer: {
                        label:
                          currFilteredSortOptions[0].attributes?.processOrder?.data?.attributes?.customersSort?.data
                            ?.attributes?.user?.data?.attributes?.name,
                        value:
                          currFilteredSortOptions[0].attributes?.processOrder?.data?.attributes?.customersSort?.data
                            ?.attributes?.user?.data?.id,
                      },
                      poc: {
                        label: currFilteredSortOptions[0].attributes?.processOrder?.data?.id,
                        value: currFilteredSortOptions[0].attributes?.processOrder?.data?.id,
                      },
                      shadeString: `${currFilteredSortOptions[0].attributes?.processOrder?.data?.attributes?.pocType}-${currFilteredSortOptions[0].attributes?.processOrder?.data?.attributes?.shade}`,
                    });
                    optionsSetter(currFilteredSortOptions, false, false);
                  }}
                  className="pull-down multi-select"
                />
              </Col>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>Job Card / Lot Number</label>
                <SelectField
                  fullWidth
                  options={jobCardOptions}
                  id="customerSort"
                  name="customerSort"
                  placeholder="Select Job Card - Lot Number"
                  value={labels.jobCard}
                  onChange={(e) => {
                    if (e.length > 0 && e[e.length - 1].value === 'all') {
                      const currJobCardOptions = jobCardOptions.slice(1, jobCardOptions.length);
                      setLabels({ ...labels, jobCard: currJobCardOptions });
                      jobCardFilterChange({ ...labels, jobCard: currJobCardOptions });
                    } else {
                      setLabels({ ...labels, jobCard: e });
                      jobCardFilterChange({ ...labels, jobCard: e });
                    }
                  }}
                  isMulti
                  multiple
                  className="pull-down multi-select"
                />
              </Col>
              <Col breakPoint={{ xs: 12, md: 4 }}>
                <label>POC</label>
                <SelectField
                  fullWidth
                  options={pocOptions}
                  onChange={(e) => {
                    const currFilteredPOCOptions = pocFilterOptions(e);
                    setLabels({
                      ...labels,
                      poc: e,
                      jobCard: [],
                      sort: '',
                      customer: {
                        label:
                          currFilteredPOCOptions[0].attributes?.processOrder?.data?.attributes?.customersSort?.data
                            ?.attributes?.user?.data?.attributes?.name,
                        value:
                          currFilteredPOCOptions[0].attributes?.processOrder?.data?.attributes?.customersSort?.data
                            ?.attributes?.user?.data?.id,
                      },
                      shadeString: `${currFilteredPOCOptions[0].attributes?.processOrder?.data?.attributes?.pocType}-${currFilteredPOCOptions[0].attributes?.processOrder?.data?.attributes?.shade}`,
                    });
                    optionsSetter(currFilteredPOCOptions, false, true, true, false);
                  }}
                  id="customerSort"
                  name="customerSort"
                  placeholder="Select POC"
                  value={labels.poc}
                  className="pull-down multi-select"
                />
              </Col>
              <Col
                breakPoint={{ xs: 12, md: 4 }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {labels.shadeString}
                </span>
              </Col>
              <Col
                breakPoint={{ xs: 12, md: 4 }}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Button
                  status="Success"
                  type="button"
                  className="btnrad"
                  shape=""
                  disabled={labels.jobCard.length === 0}
                  halfWidth
                  onClick={() => {
                    getJobCards();
                  }}
                >
                  Get Job Cards
                </Button>
                &nbsp;&nbsp;
                <Button
                  type="button"
                  className="btnrad"
                  shape=""
                  halfWidth
                  onClick={() => {
                    setLabels({
                      customer: '',
                      jobCard: [],
                      poc: '',
                      sort: '',
                    });
                    setTimeout(() => {
                      optionsSetter(apiResponse);
                    }, 500);
                  }}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {jobCards.length > 0 && (
          <Card>
            <header
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>Job Cards</span>
              <div>
                <Button
                  onClick={async () => {
                    let closedJobCardIds = jobCards.filter((t) => t.Status === 'close').map((t) => t.id);
                    let openJobCardIds = jobCards.filter((t) => t.Status === 'open').map((t) => t.id);
                    showLoader();
                    let res = await axios.post(`${baseUri()}job-card/close`, {
                      closedJobCards: closedJobCardIds,
                      openJobCards: openJobCardIds,
                      userId: userId,
                      dateTime: new Date().toISOString,
                    });
                    setJobCards([]);
                    hideLoader();
                  }}
                  status="Success"
                >
                  Save
                </Button>
              </div>
            </header>
            <CardBody>
              <Col>
                <Row
                  middle="xs"
                  start="xs"
                  style={{
                    gap: '5px',
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflow: 'auto',
                  }}
                >
                  <span
                    style={{
                      width: '5%',
                      overflow: 'hidden',
                    }}
                  >
                    JCN
                  </span>
                  <span
                    style={{
                      width: '5%',
                      overflow: 'hidden',
                    }}
                  >
                    Lot Num
                  </span>
                  <span
                    style={{
                      width: '20%',
                      overflow: 'hidden',
                    }}
                  >
                    Customer
                  </span>
                  <span
                    style={{
                      width: '20%',
                    }}
                  >
                    Sort
                  </span>
                  <span
                    style={{
                      width: '10%',
                      overflow: 'hidden',
                    }}
                  >
                    JobCard Date
                  </span>
                  <span
                    style={{
                      width: '8%',
                      overflow: 'hidden',
                    }}
                  >
                    Issue Mts
                  </span>
                  <span
                    style={{
                      width: '8%',
                      overflow: 'hidden',
                    }}
                  >
                    PKD Mts.
                  </span>
                  <span
                    style={{
                      width: '8%',
                      overflow: 'hidden',
                    }}
                  >
                    Bales
                  </span>
                  <span
                    style={{
                      width: '8%',
                      overflow: 'hidden',
                    }}
                  >
                    Diff
                  </span>
                  <span
                    style={{
                      width: '4%',
                      overflow: 'hidden',
                    }}
                  >
                    Close
                  </span>
                </Row>
                {jobCards.map((t, idx) => (
                  <Row
                    key={idx}
                    middle="xs"
                    start="xs"
                    style={{
                      gap: '5px',
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      display: 'flex',
                      flexWrap: 'nowrap',
                      overflow: 'auto',
                    }}
                  >
                    <Input
                      style={{
                        width: '5%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        type="text"
                        readOnly
                        style={{
                          width: '100%',
                        }}
                        maxLength={20}
                        value={t.id}
                      />
                    </Input>
                    <Input
                      style={{
                        width: '5%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          width: '100%',
                        }}
                        readOnly
                        value={t.id_lot_num}
                      />
                    </Input>
                    <Input
                      style={{
                        width: '20%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          width: '100%',
                        }}
                        readOnly
                        value={t.name}
                      />
                    </Input>
                    <Input
                      style={{
                        width: '20%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          width: '100%',
                        }}
                        readOnly
                        value={t.sort_name}
                      />
                    </Input>
                    <Input
                      style={{
                        width: '10%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        readOnly
                        type="date"
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        value={dateFormat(t.JCD)}
                      ></input>
                    </Input>
                    <Input
                      style={{
                        width: '8%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        readOnly
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        value={t.Issue_Mts}
                      ></input>
                    </Input>
                    <Input
                      style={{
                        width: '8%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        readOnly
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        value={t.Packed_Mts}
                      ></input>
                    </Input>
                    <Input
                      style={{
                        width: '8%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        readOnly
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        value={t.Bales}
                      ></input>
                    </Input>
                    <Input
                      style={{
                        width: '8%',
                        overflow: 'hidden',
                      }}
                    >
                      <input
                        readOnly
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        value={t.Diff}
                      ></input>
                    </Input>
                    <div
                      style={{
                        width: '4%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        checked={t.Status === 'close'}
                        onChange={(e) => {
                          if (t.Status === 'close' && !(userEmail in allowedOpenJbCrdUsers)) return;
                          handleJobCardChange(idx, 'Status', e);
                        }}
                      />
                    </div>
                  </Row>
                ))}
              </Col>
            </CardBody>
          </Card>
        )}
      </Col>
    </>
  );
};

export default CloseJobCard;
